import getWindow from "../../utils/window";
import { setRatings } from "./ratings";
import { setGeneralPageLoadAnalytics } from "./general";
import { setSingleAndMixAndMatchPageLoadValues } from "./singleVsMixAndMatch";
import { setStockStatusPageLoadValues } from "./stockStatus";
import { setSponsoredAdsPageLoadValues } from "./sponsoredAds";
import { setCategoryPageCarouselAnalytics } from "./categoryPageCarousel";
import { setFeatureProductAnalytics } from "./featuredProducts";
import { setLinkTrackEvents } from "../events";
import { addMerchEVarToProducts, getCommons } from "../common";
import sendBeacon from "../../utils/sendBeacon";
import { getProductFlags } from "./productFlags";
import getRuntimeConstants from "../../runtimeConstants";

let resolvePageLoad;

export const waitForPageLoadAnalytics = new Promise((resolve) => {
  resolvePageLoad = () => {
    const time = Date.now();
    const linkTrackEventDelay = Math.ceil(time / 1000) * 1000 - time;
    setTimeout(resolve, linkTrackEventDelay);
  };
});

function readyToFireFeatures({ product, areFeaturesReady }) {
  return product && areFeaturesReady && getWindow()?.s;
}

export const initialiseAnalytics = ({ product, areFeaturesReady, ratings }) => {
  // the page load function is *fired* after 2 events are complete
  // optimizely (features) are ready
  // and stockPrice has resolved
  // and one trip round the eventloop so that any analytics set
  // by synchronous processes resolved by those promises
  // are in place before firing

  if (!readyToFireFeatures({ product, areFeaturesReady })) {
    return;
  }

  const { isMixAndMatch } = getRuntimeConstants();

  setGeneralPageLoadAnalytics();
  setSingleAndMixAndMatchPageLoadValues({ product });
  setStockStatusPageLoadValues({ product });
  setSponsoredAdsPageLoadValues();
  setCategoryPageCarouselAnalytics();
  setFeatureProductAnalytics();

  if (product.isInStock) {
    setRatings(ratings);
  }

  const s = getWindow().s;
  if (s) {
    setTimeout(() => {
      const productFlags = getProductFlags();

      if (productFlags.length) {
        s.eVar248 = productFlags;
        s.products = addMerchEVarToProducts(
          s.products,
          `eVar247=${productFlags}`
        );
      }

      if (
        !isMixAndMatch &&
        product.restockingSoonDetails.shouldShowRestockingSoon
      ) {
        s.products = addMerchEVarToProducts(
          s.products,
          "eVar236=restocking soon"
        );
      }

      getWindow().s.linkTrackEvents = setLinkTrackEvents({
        trackingName: "pageLoad",
      });
      getCommons().setLinkTrackVars(
        ["products"],
        "eVar185",
        "eVar186",
        "eVar201"
      );
      s.t();
      resolvePageLoad();
      getWindow().asos.eventBus.emit("pageLoadAnalyticsFired");
      getWindow().dispatchEvent(new Event("pageLoadAnalyticsFired"));
      sendBeacon(product.id, "onClickBeacon");
    }, 0);
  }
};
