import getWindow from "../../utils/window";
import { addMerchEVarToProducts } from "../common";

export function setFeatureProductAnalytics() {
  const s = getWindow().s;
  const eVar193FromPLP = s.getPreviousValue(null, "gpv_eVar193");

  if (!eVar193FromPLP || eVar193FromPLP === "no value") {
    return;
  }

  s.gpv_eVar193 = eVar193FromPLP;
  s.products = addMerchEVarToProducts(s.products, `eVar193=${eVar193FromPLP}`);
}
