import { setEvents } from "../events";
import getWindow from "../../utils/window";
import { addMerchEVarToProducts } from "../common";

export function setCategoryPageCarouselAnalytics() {
  const s = getWindow().s;
  const eVar234FromPLP = s.getPreviousValue(null, "gpv_eVar234");

  if (!eVar234FromPLP || eVar234FromPLP === "no value") {
    return;
  }

  s.gpv_eVar234 = eVar234FromPLP;
  s.products = addMerchEVarToProducts(s.products, `eVar234=${eVar234FromPLP}`);

  setEvents({ events: ["event316"], trackingName: "pageLoad" });
}
